export default {
  init() {
    // JavaScript to be fired on the archive pages
  },
  finalize() {
    // JavaScript to be fired on the archive pages, after the init JS

      // Fitler Select
      $(".filter-select").on('change', function() {
        let url = $(this).val();
        if (url != '') { // require a URL
          window.location = url; // redirect
        }
        return false;
      })
  },
};
