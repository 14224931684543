import lozad from 'lozad/dist/lozad.js';
import 'featherlight/src/featherlight';
import 'featherlight/src/featherlight.gallery';

export default {
  init() {
    // JavaScript to be fired on all pages

      // Trigger Lazy Loading with Lozad
      const observer = lozad('.lozad', {
          rootMargin: '30px 0px',
          threshold: 0,
      });

      observer.observe();

      $(document).on("click", "#mobile-menu-trigger", function() {
        $(".main-menu").addClass("visible");
        $("html").addClass("mobile-menu-open");
      });

      $(document).on("click", "#mobile-menu-close", function() {
          $(".main-menu").removeClass("visible");
          $("html").removeClass("mobile-menu-open");
      });

      $(".gallery").featherlightGallery();

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
