import 'bxslider/dist/jquery.bxslider.min';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

      // Home slider
      if($('.story-carousel-slides > article').length > 1) {
          $('.story-carousel-slides').bxSlider({
              draggable: false,
              adaptiveHeight: true,
              oneToOneTouch: false,
          });
      }

  },
};
